import {computed, defineComponent, ref, Ref} from "vue";
import {ExperienceListRoute} from "./Routes/ExperienceListRoute";
import {CheckoutRoute} from "./Routes/CheckoutRoute";
import {ButtonViewRoute} from "./Routes/ButtonViewRoute";
import {UseWidgetNavigation, WidgetLocationRaw} from "./contracts";
import {DateAndTimeSelectionRoute} from "./Routes/DateAndTimeSelectionRoute";
import {IUseWidgetEvents} from "../../../../composables/useWidgetEvents";
import {WIDGET_STATE_EVENTS} from "../../../../Events/Widget/State";

export type WidgetLocation = ExperienceListRoute | CheckoutRoute | ButtonViewRoute  | DateAndTimeSelectionRoute;

type UseWidgetNavigationInput = {
    initialLocation?: WidgetLocation,
    useWidgetEvents: IUseWidgetEvents
}
const emptyLocation = defineComponent({});
export const useWidgetNavigation = ({initialLocation, useWidgetEvents }:UseWidgetNavigationInput): UseWidgetNavigation => {
    const location: Ref<WidgetLocationRaw> = ref(initialLocation ?? {name:'404', component: emptyLocation, props: {} });
    let history: (WidgetLocation&{id: string})[] = [];
    const addLocationToHistory = (location:WidgetLocation) => {
        const locationId = Math.random().toString(36).substring(7);
        history.push({...location, id:locationId});
        window.history.pushState({bkWidgetLocation: locationId},"")
    }
    const setLocation = (newLocation:WidgetLocation) => {
        location.value = newLocation;
        useWidgetEvents.emit(WIDGET_STATE_EVENTS.WIDGET_NAVIGATION_CHANGED, newLocation );
    }
    const navigateTo = (newLocation:WidgetLocation) => {
        setLocation(newLocation);
        addLocationToHistory(newLocation);
    }
    const popStateListener = (state: PopStateEvent) => {
        if(state.state && state.state.bkWidgetLocation !== undefined){
            const previousLocationIndex = history.findIndex((x) => x.id === state.state.bkWidgetLocation);
            if(previousLocationIndex>-1){
                setLocation(history[previousLocationIndex]);
            }
        }
    }

    const stopWatchingHistoryChanges = () => {
        removeEventListener("popstate", popStateListener);
    };
    const startWatchingHistoryChanges = () => {
        addEventListener("popstate", popStateListener);
    };

    if(initialLocation){
        addLocationToHistory(initialLocation);
        setLocation(initialLocation);
    }

    return {
        // Read-only computed property
       currentLocation: computed(() => location.value),
       navigateTo,
       stopWatchingHistoryChanges,
       startWatchingHistoryChanges,
   }
}


