import axios from "axios";
import {makeOnReject, onFullFilled} from "../../Services/BookingkitApi/Interceptors";
import {ExperienceService} from "../../Services/BookingkitApi";
import {DefaultApi} from "@bookingkit-private/api-v4";
import {WIDGET_API_V4_BASE_URL} from "../../Constants/environment";
import {Widget} from "../../Contracts/WidgetTypes";

export default ({logger, widgetConfiguration}: { logger:ILogger, widgetConfiguration: Widget }) => {
    const axiosInstance = axios.create({});
    axiosInstance.interceptors.response.use(onFullFilled, makeOnReject(logger));
    return  new ExperienceService(
        widgetConfiguration.id,
        new DefaultApi(undefined,
            WIDGET_API_V4_BASE_URL,
            // TODO v4, update axiosInstance to be typed
            // @ts-ignore
            axiosInstance
        ));
}