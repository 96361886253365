import 'vite/modulepreload-polyfill'
import {App, defineCustomElement, ref,} from 'vue'
import FloatingWidgetCustomElement from "./CustomElements/FloatingWIdget.ce.vue";
import '@bookingkit/coppi/dist/style.css';
import './style.css'
import './GlobalServices/index';
import bootstrap from "./bootstrap/bootstrap";
import {FLOATING_WIDGET_TYPE, FloatingWidgetType} from "./Contracts/WidgetTypes/FloatingWidgetType";
import {parseDataAttributes, legacyParseElementAttributes} from "./composables/useCustomElementProps";
import makeBookingkitApiService from "./bootstrap/Factories/BookingkitApiFactory";
import {getLogger} from "./Services/LoggingService";
import {ExperienceService} from "./Services/BookingkitApi";
import {API_V4, USE_WIDGET_EXPERIENCES, WIDGET_CONFIGURATION,} from "./Constants/di";
import UseWidgetExperience from "./Widgets/ModalWidget/Composables/useWidgetExperience";
import {bootstrapI18n} from "./Services/TranslationService";
import {isValidWidgetConfiguration} from "./composables/useWidgetConfiguration/Validators";
import {hydrateDefaults} from "./composables/useWidgetConfiguration/Mappers";

const waitToHaveElement = async (app: App<any>):Promise<ShadowRoot> => {
    return new Promise((resolve) => {
        const interval = setInterval(() => {
            if (app._container) {
                clearInterval(interval);
                resolve(app._container);
            }
        }, 0);
    });
}

const FloatingWidget = defineCustomElement(FloatingWidgetCustomElement, {
   configureApp(app) {
       bootstrap(app);
       const readyState = ref(false);
       const widgetConfiguration = ref<Required<FloatingWidgetType>|undefined>();
       app.provide('readyState', readyState);
       app.provide(WIDGET_CONFIGURATION, widgetConfiguration);

       waitToHaveElement(app).then((root) => {
              const hostElement: HTMLElement = root.host as HTMLElement;
              const configurationFromDataProperties =
                  hostElement.dataset?.widgetId
                      ? parseDataAttributes(hostElement.dataset)
                      : legacyParseElementAttributes(hostElement.attributes);

              // Throw if the configuration is not valid
              isValidWidgetConfiguration({...configurationFromDataProperties, type: FLOATING_WIDGET_TYPE});
              widgetConfiguration.value = hydrateDefaults({...configurationFromDataProperties, type: FLOATING_WIDGET_TYPE});

               // We cant access the properties of the element yet
               // So we initialise with a default language and update it later
               const i18n = bootstrapI18n({
                   // Locale gets updated later when the widget is mounted
                   // In this stage we do not know yet the configuration
                   locale: widgetConfiguration.value.locale,
                   customMessages: widgetConfiguration.value.localisation,
               });
               app.use(i18n);

               const allExperiencesToSearch = widgetConfiguration.value.experiencesForTickets.concat(widgetConfiguration.value.experiencesForVouchers)
               const experienceService = makeBookingkitApiService({
                   logger: getLogger(),
                   widgetConfiguration: widgetConfiguration.value,
               });

               app.provide<ExperienceService>(API_V4,
                   experienceService
               );
               const useWidgetExperience = UseWidgetExperience(experienceService, allExperiencesToSearch);
               app.provide(USE_WIDGET_EXPERIENCES, useWidgetExperience);
               readyState.value = true;

       });
    },
});

const registerElements = () => {
    // Register the custom element.
    // After registration, all `<my-vue-element>` tags
    // on the page will be upgraded.
    customElements.define('bk-floating-widget', FloatingWidget )
}
registerElements();

